@use "sass:math";

$pc-vars: (
	'btn_next': (
		offset-x: 0px,
		offset-y: 0px,
		width: 28px,
		height: 28px,
		total-width: 60px,
		total-height: 28px,
		imageSrc: '../img/pc.png'
	),
	'btn_prev': (
		offset-x: -32px,
		offset-y: 0px,
		width: 28px,
		height: 28px,
		total-width: 60px,
		total-height: 28px,
		imageSrc: '../img/pc.png'
	),
);
$pc-origin: (
  total-width: 60px,
  total-height: 28px,
  imageSrc: '../img/pc.png'
);

@mixin sprite-pc-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-pc-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-pc-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-pc {
  @include sprite-pc-size($pc-origin);
  @include sprite-pc-image($pc-origin);
}

@mixin sp-pc($image, $size: true) {
  @include set-pc;
	background-position: math.div(map-get(map-get($pc-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($pc-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($pc-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($pc-vars, $image), 'height'), 2);
	}
}
