.news-detail {
  .top_area {
    .top_title {
      width: 183px;
      background-position: 0 -53px;
    }
  }
}
.news {
  .top_area {
    .top_title {
      width: 183px;
      background-position: 0 -53px;
    }
  }

  .content,
  &-detail .content {
    padding-bottom: 90px
  }

  &__wrap {
    margin-top: 64px;
  }

  &__list {
    overflow: hidden;
    margin-left: -40px;
  
    li {
      float: left;
      width: 346px;
      min-height: 348px;
      margin: 60px 0 0 40px;
  
      a {
        display: block;
        font-size: 14px;
        line-height: 24px;
      }
    }
  
    .list_tit {
      overflow: hidden;
      display: block;
      max-height: 48px;
      padding: 0 10px;
      margin-top: 21px;
      font-size: 16px;
      color: #000;
      font-weight: normal;
    }
  
    p {
      overflow: hidden;
      max-height: 48px;
      padding: 0 10px;
      margin-top: 10px;
      color: #808080;
    }
  
    .no_data {
      float: none;
      width: auto;
      text-align: center;
  
      p {
        padding: 155px 0 154px;
        font-size: 26px;
        color: #cfcfcf;
      }
    }
  
    .date {
      display: block;
      padding: 0 10px;
      margin-top: 13px;
      color: #808080;
      line-height: 12px;
      @include font-helve;
  
      &_mo {
        display: none;
      }
    }
  }

  // &__paginate {
  //   margin-top: 60px;
  //   padding-top: 35px;
  //   border-top: 1px solid #999fa9;
  //   text-align: center;
  
  //   a {
  //     display: inline-block;
  //     width: 25px;
  //     height: 20px;
  //     margin: 0 4px;
  //     @include font-helve;
  //     font-size: 16px;
  //     color: #999fa9;
  //     vertical-align: middle;
  
  //     &.on {
  //       color: $font-point;
  //     }
  //   }
  
  //   .next, .prev {
  //     width: 20px;
  //     height: 20px;
  //     @extend %sub_bg;
  //     background-position: -286px 5px;
  //   }
  
  //   .prev {
  //     background-position: -300px -80px;
  //   }
  // }

  &__title {
    .title {
      font-size: 32px;
      color: #000;
      line-height: 46px;
      font-weight: normal;
    }

    .date {
      display: inline-block;
      margin-top: 15px;
      @include font-helve;
      font-size: 14px;
      color: #808080;
    }
  }

  &__contents {
    padding: 40px 0 52px;
    border-bottom: 1px solid #999fa9;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #666;

    p {
      margin-top: 26px;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }
    }

    img+p {
      margin-top: 54px;
    }

    p+img {
      margin-top: 32px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &__btn {
    margin-top: 40px;
    text-align: left;

    .button:first-child {
      *float: left;
    }

    .btn_side {
      display: none;
    }
  }
  
  .btn_w {
    display: block;
    width: 120px;
    margin: 95px auto 0;
  }
}


@include breakpoint(mobile) {
  .news-detail {
    .top_area {
      .top_title {
        width: 92px;
        background-position: 0 4px;
      }
    }
    .content {
      padding: 0 0 50px;
    }
  }

  .news {
    .top_area {
      .top_title {
        width: 92px;
        background-position: 0 4px;
      }
    }
    &__wrap {
      padding: 0 20px;
      margin-top: 36px;
    }

    &__title {
      padding-bottom: 21px;
      border-bottom: 1px solid $font-gray;

      .title {
        font-size: pxrem(20);
        color: #1a2028;
        line-height: 29px;
        font-weight: normal;
        letter-spacing: 0;
      }

      .date {
        display: block;
        margin-top: 11px;
        margin-bottom: 0;
        font-family: $font-helve;
        font-size: pxrem(12);
        color: $font-normal;
        letter-spacing: 0;
      }
    }

    &__contents {
      padding: 25px 0 45px;
      border-top: none;
      border-bottom: 1px solid $font-gray;
      text-align: left;
      font-size: 14px;
      line-height: 23px;
      color: #666;

      img {
        width: 100%;
        height: auto;
      }

      p {
        margin-top: 20px;
        line-height: 23px;
        color: #666;
      }

      img+p, p+img {
        margin-top: 13px;
      }
    }

    &__list {
      overflow: auto;
      margin-left: 0;

      li {
        float: none;
        width: auto;
        min-height: auto;
        margin: 0;
        padding-top: 29px;
        border-bottom: 1px solid #f0f0f0;

        a {
          display: block;
          padding-bottom: 28px;

          img {
            height: auto;
          }
        }
      }

      .list_tit {
        @include ellp2;
        max-height: none;
        margin-top: 17px;
        padding: 0;
        font-size: pxrem(16);
        color: #000;
        line-height: 24px;
        font-weight: normal;
      }

      .date {
        display: none;

        &_mo {
          display: block;
          margin-top: 6px;
          font-size: pxrem(12);
          color: $font-normal;
          font-family: $font-helve;
        }
      }

      p {
        max-height: none;
        padding: 0;
        margin-top: 14px;
        line-height: 23px;
        @include ellp2;
      }

      .no_data {
        padding: 110px 0 75px;
        border: 0;
        text-align: center;
        color: #cfcfcf;
        font-size: 20px;

        p {
          font-size: 20px;
        }
      }
    }

    // &__paginate {
    //   display: none;
    // }
    
    &__btn {
      margin-top: 20px;
      text-align: left;

      &:after {
        @include clear;
      }

      .btn_m {
        font-weight: normal;
      }

      .fr {
        display: none;
      }

      .btn_side {
        display: block;
        float: right;
      }
      
      .prev, .next {
        float: left;
        width: 35px;
        min-width: 35px;
        height: 35px;
        padding: 0;
        border: 1px solid #e6e6e6;
  
        &:after {
          display: inline-block;
          width: 7px;
          height: 15px;
          @include sub_sp;
          content: '';
        }
      }
  
      .prev {
        margin-right: 0;
  
        &:after {
          background-position: 0 -86px;
        }
      }
  
      .next {
        margin-left: 0;
  
        &:after {
          background-position: -12px -86px;
        }
      }
    }

    .btn_w {
      width: auto;
      margin-top: 30px;
  
      &.is-hide {
        display: none;
      }
    }
  }
}
