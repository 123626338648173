@include breakpoint(mobile) {
  .service {
    .content {
      margin-bottom: 0;
      padding: 0;
    }

    .top_area {
      overflow: hidden;
      height: 100px;

      .image {
        display: none;
      }

      .top_title {
        height: 30px;
        margin: 45px 0 0 20px;
        background-position: 0 -111px;
      }
    }

    &__box {
      border-top: none;
    }

    &__list {
      width: auto;
      padding: 0;
    }

    &__item {
      display: table-cell;
      position: relative;
      width: 100%;
      min-height: 90px;
      margin: 0 auto;
      padding: 25px 15px 30px 20px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      border-top: 1px solid #f0f0f0;

      &-box {
        display: table-cell;
        vertical-align: top;
      }

      &.snow {
        border: 0;
        padding: 30px 15px 30px 20px;

        .service {
          &__name {
            width: 55px;
            background-position: -100px 0;
          }

          &__img {
            background-position: 0 0;
          }

          &__info {
            padding-bottom: 5px;
          }

          &__ico {
            top: 5px;
          }
        }
      }

      &.b612 {
        .service {
          &__name {
            width: 41px;
            background-position: -100px -20px;
          }
          
          &__img {
            background-position: 0 -100px;
          }
        }
      }

      &.foodie {
        .service {
          &__name {
            width: 56px;
            background-position: -100px -40px;
          }
          
          &__img {
            background-position: 0 -200px;
          }
        }
      }

      &.looks {
        margin-right: 0;

        .service {
          &__name {
            width: 61px;
            background-position: -100px -80px;
          }
          
          &__img {
            background-position: 0 -400px;
          }
        }
      }

      &.line_camera {
        .service {
          &__name {
            width: 109px;
            background-position: -100px -60px;
          }
          
          &__img {
            background-position: 0 -300px;
          }
        }
      }

      &.zepeto {
        .service {
          &__name {
            width: 70px;
            background-position: -100px -100px;
          }
          
          &__img {
            background-position: 0 -500px;
          }
        }
      }

      &.cake {
        .service {
          &__name {
            width: 50px;
            background-position: -100px -179px;
          }
          
          &__img {
            background-position: -100px -500px;
          }
        }
      }

      &.jamlive {
        .service {
          &__name {
            width: 75px;
            background-position: -100px -120px;
          }
          
          &__img {
            background-position: -100px -200px;
          }
        }
      }

      &.soda {
        .service {
          &__name {
            width: 50px;
            background-position: -100px -139px;
          }
          
          &__img {
            background-position: -100px -300px;
          }
        }
      }

      &.kream {
        .service {
          &__name {
            width: 65px;
            background-position: -100px -159px;
          }
          
          &__img {
            background-position: -100px -400px;
          }
        }
      }

      &.c_studio {
        .service {
          &__name {
            width: 130px;
            background-position: -213px 0;
          }
          
          &__img {
            background-position: 0 -600px;
          }
        }
      }

      &.epik {
        .service {
          &__name {
            width: 130px;
            background-position: -213px -19px;
          }
          
          &__img {
            background-position: -200px -200px;
          }
        }
      }

      &.vita {
        .service {
          &__name {
            width: 130px;
            background-position: -212px -39px;
          }
          
          &__img {
            background-position: -200px -300px;
          }
        }
      }

      &.vday {
        .service {
          &__name {
            width: 130px;
            height: 17px;
            background-position: -212px -60px;
          }
          
          &__img {
            background-position: -200px -300px;
          }
        }
      }

      &.glowup {
        .service {
          &__name {
            width: 68px;
            height: 17px;
            background-position: -212px -82px;
          }
          
          &__img {
            background-position: -100px -600px;
          }
        }
      }

      &+.service__item {
        margin-left: 0;
      }

      &--signle {
        width: 100%;

        .service_info {
          max-width: 370px !important;
        }
      }
    }

    &__name {
      display: inline-block;
      height: 14px;
      margin-top: 5px;
      background: url(/img/mo/m_sp_service_v3.png) no-repeat;
      background-size: 343px 690px;
      -webkit-background-size: 343px 690px;
    }

    &__img {
      width: 90px;
      height: 90px;
      margin-right: 0;
      background: url(/img/mo/m_sp_service_v3.png) no-repeat;
      background-size: 343px 690px;
      -webkit-background-size: 343px 690px;
    }

    /* start: Wikit 신규 텍스트 적용 */

    .service__item.wikit .service__name {
      width: 45px;
      height: 16px;
      background: url(/img/mo/m_wikit_text_image.png) no-repeat center/cover;
    }

    /* end: Wikit 신규 텍스트 적용 */

    &__info {
      display: table-cell;
      padding-left: 20px;
      vertical-align: middle;
    }

    &__txt {
      margin-top: 7px;
      font-size: 12px;
      line-height: 19px;
    }

    &__ico {
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 20;
      margin-top: 24px;

      &-item {
        margin-left: 7px;
        margin-right: 0;
  
        &.ios {
          .service__ico-link.sp {
            background-position: 0 -198.5px;
          }
        }
    
        &.android {
          .service__ico-link.sp {
            background-position: -35px -198.5px;
          }
        }
      }

      &-link {
        width: 30px;
        height: 30px;
        background: url(/img/mo/m_sp.png) no-repeat;
        background-position: 0 -198.5px;
        background-size: 200px auto;
      }
    }
  }
}
