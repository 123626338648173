.main {
  &__view {
    overflow: hidden;
    position: relative;
    height: 610px;
    background: #53d8f2 url(/img/pc/bg_spot.png) no-repeat 50% 0;
    background-size: 2048px 610px;
    -webkit-background-size: 2048px 610px;
  }

  &__section {
    padding: 60px 0 54px;

    //inc
    &.inc {
      margin-bottom: 81px;
      padding: 80px 0 264px;
      background: url(/img/pc/bg_main_v5.png) 50% 100% no-repeat;
      background-size: 1120px 259px;
      -webkit-background-size: 1120px 259px;

      .main {
        &__title {
          width: 247px;
          height: 36px;
          background-position: 0 -34px;
        }

        &__text {
          margin-top: 16px;
          padding-bottom: 19px;
          line-height: 26px;
        }
      }
    }

    //career
    &.career {
      overflow: hidden;
      position: relative;
      height: 350px;
      background: #213338 url(/img/pc/bg_career.png) no-repeat 50% 0;
      background-size: 2048px 350px;
      -webkit-background-size: 2048px 350px;
      box-sizing: border-box;

      .main {
        &__title {
          width: 163px;
          background-position: 0 -114px;
        }

        &__text {
          color: $color-white;
        }

        &__btn {
          margin-top: 44px;
          color: $color-white;
        }
      }
    }
    
    //news
    &.news {
      padding-bottom: 74px;

      .main__title {
        width: 121px;
        background-position: 0 -154px;
      }
    }
  }
  
  &__inside {
    max-width: 1120px;
    margin: 0 auto;

    .main__mo-img {
      display: none;
    }
  }

  &__title {
    display: block;
    height: 30px;
    @extend %bg;
  }

  &__service-link {
    font-size: 14px;
    color: $font-normal;
    text-decoration: underline;

    &:hover {
      color: #26c9ee;
    }
  }

  &__text {
    margin-top: 25px;
    font-size: 14px;
    color: $font-gray;
    line-height: 24px;

    &.text-mo {
      display: none;
    }
  }

  &__list {
    overflow: hidden;
    margin-top: 38px;
  }

  &__item {
    float: left;
    width: 490px;
    margin-left: 50px;
    padding-left: 30px;
    border-left: 1px solid #e6e6e6;

    &:first-child {
      margin-left: 0;
      padding-left: 0;
      border: 0;
    }

    &-link {
      display: block;
      max-height: 110px;
    }

    &-tit {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: normal;
      font-size: 16px;
      color: $font-normal;
    }

    &-date {
      display: inline-block;
      margin: 7px 0 12px;
      @include font-helve;
      font-size: 14px;
      color: $font-gray;
    }

    &-txt {
      display: -webkit-box;
      overflow: hidden;
      max-height: 48px;
      word-wrap: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 14px !important;
      color: $font-gray !important;
      line-height: 24px !important;
    }
  }

}

@include breakpoint(mobile) {
  .main {
    &__section {
      padding: 0 20px 34px;
      font-size: 14px;
      color: $font-gray;

      &.inc {
        margin-bottom: 0;
        padding: 0 20px 42px;
        background: none;

        .main {
          &__title {
            width: 179px;
            height: 27px;
            background-position: 0 -145px;
          }

          &__text {
            margin-top: 21px;
            padding-bottom: 0;
            line-height: 23px;
          }

          &__mo-img {
            display: block;
            margin-top: 16px;
            width: 100%;
          }

          &__service-link {
            display: inline-block;
            margin-top: 12px;
            text-decoration: underline;
          }
        }
      }
  
      &.career {
        height: 357px;
        padding: 0 20px;
        border-top: 0;
        background: #2b4049 url(/img/mo/m_bg_career.png) no-repeat 50% 0;
        background-size: 1077px 357px;
        -webkit-background-size: 1077px 357px;

        .main {
          &__title {
            width: 141px;
            margin-top: 98px;
            background-position: 0 -172px;
          }

          &__text {
            color: $color-white;
          }

          &__btn {
            margin-top: 25px;
            font-weight: normal;
          }
        }
      }
  
      &.news {
        padding-bottom: 47px;
  
        .main__title {
          width: 90px;
          background-position: 0 -91px;
        }
      }
    }

    &__title {
      height: 22px;
      margin-top: 40px;
      @include sp;
    }

    &__text {
      margin-top: 21px;
      line-height: 23px;

      &.text-pc {
        display: none;
      }

      &.text-mo {
        display: block;
      }
    }

    &__view {
      height: 321px;
      background: #53d8f2 url(/img/mo/m_bg_main_full.png) no-repeat 50% 0;
      background-size: 1077px 321px;
      -webkit-background-size: 1077px 321px;
    }

    &__list {
      margin-top: 21px;
    }

    &__item {
      width: 100%;

      &:last-child {
        display: none;
      }

      &-link {
        overflow: hidden;
        max-height: none;
      }

      &-tit {
        display: -webkit-box;
        overflow: hidden;
        word-wrap: break-word;
        white-space: inherit;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: normal;
        font-family: $font-helve;
        font-size: 1.14286rem;
        line-height: 22px;
        color: $color-black;
      }

      &-date {
        display: block;
        margin: 11px 0 13px;
        font-size: 12px;
        color: $font-normal;
      }

      &-txt {
        font-size: 14px;
        color: $font-gray !important;
        line-height: 23px !important;
      }
    }
  }
}
