.ethics {
  .container {
    background-color: $color-white;
  }
  
  .cont-wrap {
    &__title {
      &+.ethics__txt {
        margin-top: 24px;
      }
    }
  }

  &__col-mo {
    display: none;
  }

  &__tr {
    &.post-mo {
      display: none;
    }
  }

  &__txt {
    margin-top: 27px;
    font-size: 14px;
    color: $font-normal;
    line-height: 26px;
  }

  &__tbl {
    width: 100%;
    margin-top: 33px;
    border-top: 1px solid #999fa9;
  
    .ethics__txt {
      margin: 0;  
    }
  }

  &__th {
    padding-left: 45px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    font-weight: normal;
    color: $color-black;
    text-align: left;
  }

  &__td {
    height: 47px;
    padding: 16px 30px;
    border: 1px solid #e6e6e6;
    border-width: 0 0 1px 1px;
    font-size: 14px;
    color: $font-normal;
    line-height: 26px;
  }

  &__btn {
    margin-top: 8px;
  }
}

@include breakpoint(mobile) {
  .ethics {
    .container {
      .content {
        padding: 39px 20px 60px;
      }
    }

    .cont-wrap {
      &__title {
        &+.ethics__txt {
          margin-top: 21px;
        }
      }
    }
    
    &__col-pc {
      display: none;
    }

    &__col-mo {
      display: table-column;
    }

    &__tr {
      &.post-pc {
        display: none;
      }
  
      &.post-mo {
        display: table-row;
      }
    }


    &__txt {
      margin-top: 23px;
      line-height: 22px;
      font-size: 13px;
    }

    &__tbl {
      margin-top: 24px;

      .ethics__txt {
        margin-top: 0;
        font-size: 13px;
        line-height: 16px;
      }
    }

    &__th {
      position: relative;
      padding: 10px 10px 10px 11px;
      color: $color-black;
      font-weight: normal;
      border-color: #ebebeb;
      font-size: 12px;
    }

    &__td {
      height: auto;
      padding: 11px 15px;
      font-size: 12px;
      line-height: 16px;
    }

    &__btn {
      margin-top: 5px;
      font-weight: normal;
    }
  }
}


