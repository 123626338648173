@use "sass:math";

$mo-vars: (
	'btn_next': (
		offset-x: 0px,
		offset-y: 0px,
		width: 28px,
		height: 28px,
		total-width: 60px,
		total-height: 28px,
		imageSrc: '../img/mo.png'
	),
	'btn_prev': (
		offset-x: -32px,
		offset-y: 0px,
		width: 28px,
		height: 28px,
		total-width: 60px,
		total-height: 28px,
		imageSrc: '../img/mo.png'
	),
);

$mo-origin: (
  total-width: 60px,
  total-height: 28px,
  imageSrc: '../img/mo.png'
);

@mixin sprite-mo-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-mo-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

%set-mo {
	@include sprite-mo-size($mo-origin);
	@include sprite-mo-image($mo-origin);
}

@mixin sp-mo($image, $size: true) {
	@include sprite-mo-size($mo-origin);
	@include sprite-mo-image($mo-origin);
	background-position: math.div(map-get(map-get($mo-vars, $image), 'offset-x'), 2) math.div(map-get(map-get($mo-vars, $image), 'offset-y'), 2);

	@if $size {
		width: math.div(map-get(map-get($mo-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($mo-vars, $image), 'height'), 2);
	}
}
