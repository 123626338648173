.layer-popup {
  &__wrap {
    .btn_top_wrap {
      display: none;
    }

    html:lang(en) & {
      width: 720px;
    }
    html:lang(ja) & {
      width: 702px;
    }
    html:lang(zh-Hans) &,
    html:lang(zh-Hant) & {
      width: 678px;
    }
  }

  &__header {
    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }

  &__inner {
    padding: 0 50px 25px;
  }

  &__title {
    display: block;
    padding: 41px 0 20px;
    font-size: 28px;
    font-weight: normal;
    color: #000;
    border-bottom: 1px solid #e6e6e6;
  }

  .necessary {
    position: absolute;
    top: 64px;
    right: 51px;
    color: #666;
    font-size: 13px;

    &:before {
      display: inline-block;
      width: 12px;
      height: 8px;
      margin: 4px 4px 0 0;
      background: url(../img/pc/sp_sub_ico.png) no-repeat;
      background-position: 0 -173px;
      vertical-align: top;
      content: '';
    }
  }

  &__btn {
    margin-top: 20px;
    padding: 20px 0 30px;
    border-top: 1px solid #e6e6e6;
    text-align: center;

    .button {
      cursor: pointer;
      line-height: 50px;
    }

    .mo_view {
      display: none;
    }
  }

  .input {
    &__area {
      overflow: hidden;
  
      li {
        display: flex;
        overflow: hidden;
        position: relative;
        margin-top: 8px;
  
        &:first-child {
          margin-top: 20px;
        }
  
        &:before {
          position: absolute;
          top: 17px;
          left: 1px;
          width: 12px;
          height: 8px;
          background: url(../img/pc/sp_sub_ico.png) no-repeat;
          background-position: 0 -173px;
          content: '';
        }
  
        &.is-not-require {
          &:before {
            display: none;
          }
        }
      }
    }

    &__text,
    &__textarea,
    &__select {
      display: block;
      position: relative;
      width: 100%;
      height: 20px;
      padding: 10px 13px;
      font-size: 14px;
      color: #666;
      border: 1px solid #808080;
      line-height: 22px;
      box-sizing: content-box;

      &::placeholder {
        color: #b2b2b2;
      }
    }

    &__select {
      padding: 10px 20px 10px 9px;
    }

    &__textarea {
      height: 160px;
      padding: 10px 13px;
      resize: none;
      line-height: 21px;
    }

    &__label {
      display: flex;
      align-items: center;
      position: relative;
      min-width: 94px;
      height: 42px;
      padding: 0 3px 0 23px;
      font-size: 14px;
      color: #666;
      line-height: 20px;

      html:lang(en) & {
        min-width: 164px;
      }
      html:lang(ja) & {
        min-width: 147px;
      }
      html:lang(zh-Hans) &,
      html:lang(zh-Hant) & {
        min-width: 123px;
      }
    }
  }
  

  .privacy {
    &__title {
      margin: 22px 0 15px;
      color: #1a2028;
      font-size: 16px;
    }

    &__info {
      overflow-y: auto;
      height: 133px;
      padding: 12px 12px 10px;
      border: 1px solid #808080;
      color: #666;
      font-size: 13px;
      line-height: 19px;

      strong {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.8px;
      }
    }

    &__agreement {
      margin-top: 12px;

      input {
        width: 18px;
        height: 18px;
        margin: -1px 7px 0 0;
        padding: 0;
        vertical-align: top;
        -webkit-appearance: checkbox;
        appearance: checkbox;
      }
    
      label {
        display: inline-block;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .btn_close {
    display: none;
  }
}



@include breakpoint(mobile) {
  .layer-popup {
    &__wrap {
      width: 100%;
      height: max-content;

      html:lang(en) &,
      html:lang(ja) &,
      html:lang(zh-Hans) &,
      html:lang(zh-Hant) & {
        width: 100%;
      }
    }

    &__inner {
      position: relative;
      padding: 64px 20px 50px;
    }

    &__header {
      padding: 0 0 13px;
    }

    &__title {
      float: left;
      padding: 0;
      border: none;
      font-size: 1.85714rem;
      color: #000;
      font-weight: normal;
    }

    .necessary {
      position: static;
      float: right;
      margin-top: 16px;
      color: #666;
      font-size: 12px;

      &:before {
        display: inline-block;
        width: 9px;
        height: 6px;
        margin: 5px 6px 0 0;
        background: url(/img/mo/m_sp_sub.png) 0 -206px no-repeat;
        content: '';
      }
    }

    .btn_close {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      padding: 15px 20px;

      .sp {
        width: 12px;
        height: 18px;
        background: url(../img/mo/m_sp_sub.png) no-repeat;
        background-position: -139px 0;
        background-size: 150px auto;
        vertical-align: top;
        line-height: 999px;
      }
    }

    &__btn {
      margin-top: 30px;
      padding: 0;
      border: none;

      .mo_view {
        display: inline-block;
        line-height: normal;
      }

      .button {
        min-width: 170px;
        height: 50px;
        font-size: 1.14286rem;
        line-height: 50px;
      }

      .js-layer-closer {
        display: none;
      }
    }

    .input {
      &__area {
        li {
          position: relative;
          margin-top: 6px;
  
          &:first-child {
            margin-top: 0;
          }
  
          &:before {
            top: 20px;
            left: 10px;
            width: 9px;
            height: 6px;
            background: url(../img/mo/m_sp_sub.png) 0 -206px no-repeat;
          }
        }
  
      }
      &__label {
        display: none;
      }

      &__text,
      &__select {
        height: 45px;
        padding: 16px 15px 14px 23px;
        border: 1px solid #ebebeb;
        line-height: 16px;
        font-size: pxrem(14);
        color: $font-gray;
        @include boxsizing;
      }

      &__textarea {
        height: 235px;
        padding: 13px 15px 15px 23px;
        border: 1px solid #ebebeb;
        line-height: 20px;
        font-size: pxrem(14);
        @include boxsizing;
        resize: none;
        vertical-align: top;
      }
    }

    .privacy {
      &__title {
        margin: 18px 0 0;
        font-size: 14px;
      }
  
      &__info {
        height: auto;
        margin-top: 8px;
        padding: 4px 8px 5px 8px;
        border-color: #ebebeb;
        color: #666;
        font-size: 12px;
        line-height: 22px;
  
        strong {
          font-size: 15px;
        }
      }
  
      &__agreement {
        input {
          width: 14px;
          height: 14px;
          margin: -1px 5px 0 0;
        }
      
        label {
          font-size: 14px;
          line-height: 1.25;
        }
      }
    }
  }
}
