//select custom
.select_box{
	position:relative;
	display:inline-block;
	*display: inline;
	*zoom:1;
	#header &{
		float:right;
		margin-top:25px;
	}
	.selected{
		position: relative;
		display:inline-block;
		height:40px;
		min-width:69px;
		padding:0 32px 0 29px;
		color:#999fa9;
		font-size:13px;
		line-height:42px;
		border:1px solid #e6e6e6;
		-webkit-border-radius:50px;
		-moz-border-radius:50px;
		border-radius:50px;
		@extend %bg;
		background-position:100px -257px;
	}
	.select_list{
		display:none;
		position:absolute;
		top:34px;
		left:0;
		right:0;
		padding-bottom:3px;
		z-index:100;
		border:1px solid #e6e6e6;
		border-top:0;
		-webkit-border-bottom-left-radius:20px;
		-webkit-border-bottom-right-radius:20px;
		-moz-border-bottom-left-radius:20px;
		-moz-border-bottom-right-radius:20px;
		border-bottom-left-radius:20px;
		border-bottom-right-radius:20px;
		background-color:#fff;
		ul{
			width:100%;
			padding-top:4px;
			li.on a{
				color:$font-point;
			}
		}
		a{
			display:block;
			padding:0 29px;
			line-height:34px;
			color:#999fa9;
			font-size:13px;
			&:hover{
				color:$font-point;
			}
		}
	}
	&.open .select_list{
		display:block;
	}
	&.open .selected{
		border:1px solid #e6e6e6;
		border-radius: 0;
		-webkit-border-top-left-radius:20px;
		-webkit-border-top-right-radius:20px;
		-moz-border-top-left-radius:20px;
		-moz-border-top-right-radius:20px;
		border-top-left-radius:20px;
		border-top-right-radius:20px;
		background-position:-115px -273px;
	}
}

//select2
.select_box2{
	position: relative;
	display:inline-block;
	*display:inline;
	*zoom:1;
	*margin-right:10px;
	height:40px;
	min-width:150px;
	background:#fff;
	border:1px solid #e6e6e6;
	vertical-align: top;
	z-index:100;
	&+&{
		margin-left:10px;
	}
	.selected{
		display:block;
		padding:0 45px 0 20px;
		color:$font-normal;
		font-size:14px;
		line-height:42px;
		white-space: nowrap;
		.arr{
			position:absolute;
			top:0;
			right:0;
			display:inline-block;
			width:40px;
			height:100%;
			border-left:1px solid #e6e6e6;
			background-position:-239px 16px;
			font-size:0;
			line-height:0;
		}
	}
	.select_list{
		display:none;
		position: absolute;
		top:40px;
		left:-1px;
		right:-1px;
		padding:10px 0;
		border:1px solid #e6e6e6;
		background:#fff;
		z-index:10;
		a{
			display:block;
			padding:0 20px;
			line-height: 30px;
			font-size:14px;
			color:$font-normal;
			&:hover,.on &{
				color:$font-point;
			}
		}
	}
	&.open .select_list{
		display:block;
	}
}

.form_field{
	overflow: hidden;
	margin:12px 0 20px;
	.fieldlabel{
		float:left;
		min-width:89px;
		height:42px;
		padding:8px 15px 0 16px;
		font-size:14px;
		color:#666;
		line-height: 42px;
		.ness{
			display: inline-block;
			width:5px;
			height:5px;
			margin:19px 5px 0 -13px;
			background-position:-252px -85px;
			vertical-align:top;
			*margin-top:6px;
		}
		label {
			display:inline-block;
			line-height:20px;
			vertical-align: middle;
		}
	}
	.fielditem{
		overflow: hidden;
		position: relative;
		padding-top:8px;
	}
	input,textarea{
		width:402px;
		height:20px;
		padding: 10px 13px;
		font-size:14px;
		color:#666;
		border:1px solid #808080;
		line-height:22px;
	}
	textarea{
		height:160px;
		padding:15px 13px;
		resize: none;
		line-height:20px;
		vertical-align: top;
		& + .placeholder{
			top:15px;
			height: auto;
		}
	}
	.field_select{
		position:relative;
		width:100%;
		height:42px;
		padding-left:10px;
		border:1px solid #808080;
		background-color:#fff;
		color:#666;
		font-size:14px;
	}
	.placeholder{
		position: absolute;
		top:8px;
		left:0;
		right:0;
		bottom: 0;
		height: 20px;
		padding:10px 14px;
		font-size:14px;
		color:#b2b2b2;
		line-height:22px;
		white-space:nowrap;
		z-index:-1;
	}
	.fielditem.on .placeholder{
		display:none;
	}
}

.ico {
    @extend %sub_bg;
}