.service {
  .content {
    max-width: 100%;
    margin-bottom: 50px;
    padding-top: 0;

    &:after {
      @include clear;
    }
  }

  .top_area {
    .top_title {
      width: 293px;
      height: 59px;
      background-position: 0 -191px;
    }
  }

  &__box {
    border-top: 1px solid #f2f2f2;

    &:first-of-type {
      border-top: none;
    }
  }

  &__list {
    overflow: hidden;
    width: 1120px;
    margin: 0 auto;
    padding: 60px 0;
  }

  &__item {
    overflow: hidden;
    float: left;
    width: 546px;

    &.snow {
      .service {
        &__name {
          width: 135px;
          background-position: -420px 0;
        }

        &__img {
          background-position: 0 0;
        }
      }
    }

    &.b612 {
      margin-right: 0;

      .service {
        &__name {
          width: 88px;
          background-position: -420px -40px;
        }

        &__img {
          background-position: -210px 0;
        }
      }
    }

    &.foodie {
      .service {
        &__name {
          width: 136px;
          background-position: -420px -80px;
        }

        &__img {
          background-position: 0 -210px;
        }
      }
    }

    &.looks {
      margin-right: 0;
      
      .service {
        &__name {
          width: 145px;
          background-position: -420px -120px;
        }

        &__img {
          background-position: -210px -210px;
        }
      }
    }

    &.line_camera {
      .service {
        &__name {
          width: 243px;
          background-position: -420px -160px;
        }

        &__img {
          background-position: 0 -420px;
        }
      }
    }

    &.zepeto {
      .service {
        &__name {
          width: 243px;
          background-position: -420px -200px;
        }

        &__img {
          background-position: -210px -420px;
        }
      }
    }

    &.cake {
      .service {
        &__name {
          width: 125px;
          background-position: -420px -362px;
        }

        &__img {
          background-position: -210px -840px;
        }
      }
    }

    &.jamlive {
      .service {
        &__name {
          width: 173px;
          background-position: -420px -242px;
        }

        &__img {
          background-position: 0 -630px;
        }
      }
    }

    &.soda {
      .service {
        &__name {
          width: 123px;
          background-position: -420px -282px;
        }

        &__img {
          background-position: -210px -630px;
        }
      }
    }

    &.kream {
      .service {
        &__name {
          width: 150px;
          background-position: -420px -322px;
        }

        &__img {
          background-position: 0 -840px;
        }
      }
    }

    &.c_studio {
      .service {
        &__name {
          width: 280px;
          background-position: -420px -400px;
        }

        &__img {
          background-position: 0 -1050px;
        }
      }
    }

    &.epik {
      .service {
        &__name {
          width: 280px;
          background-position: -420px -439px;
        }

        &__img {
          background-position: -210px -1050px;
        }
      }
    }

    &.vita {
      .service {
        &__name {
          width: 280px;
          background-position: -420px -479px;
        }

        &__img {
          background-position: -430px -1050px;
        }
      }
    }

    &.vday {
      .service {
        &__name {
          width: 280px;
          height: 34px;
          background-position: -420px -519px;
        }

        &__img {
          background-position: -430px -1050px;
        }
      }
    }

    &.glowup {
      .service {
        &__name {
          width: 160px;
          height: 34px;
          background-position: -420px -567px;
        }

        &__img {
          background-position: 0 -1260px;
        }
      }
    }

    &+.service__item {
      margin-left: 28px;
    }

    &--single {
      width: 600px;

      .service__info {
        max-width: 370px !important;
      }
    }
  }

  &__img {
    float: left;
    width: 200px;
    height: 200px;
    margin-right: 30px;
    background: url(/img/pc/sp_services_v3.png) no-repeat;
  }

  &__info {
    display: inline-block;
    max-width: 316px;
  }

  &__name {
    display: inline-block;
    height: 28px;
    margin-top: 12px;
    background: url(/img/pc/sp_services_v3.png) no-repeat;
  }

  /* start: Wikit 신규 이미지 및 텍스트 적용 */

  .service__item.wikit .service__img {
    background: url(/img/pc/wiki_logo.png) no-repeat center/cover;
  }

  .service__item.wikit .service__name {
    width: 121px;
    height: 34px;
    background: url(/img/pc/wikit_text_image.png) no-repeat center/cover;
  }

  /* end: Wikit 신규 이미지 및 텍스트 적용 */

  &__txt {
    margin-top: 6px;
    font-size: 14px;
    color: #808080;
    line-height: 24px;
  }

  &__ico {
    overflow: hidden;
    margin-top: 24px;

    &-item {
      float: left;
      margin-right: 10px;
  
      &.ios {
        .service__ico-link.sp {
          background-position: -20px -194px;
        }
      }
  
      &.android {
        .service__ico-link.sp {
          background-position: -100px -194px;
        }
      }
    }
  
    &-link {
      display: block;
      width: 70px;
      height: 70px;
    }
  }
}
