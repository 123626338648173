#header {
  height: 90px;
  max-width: 1120px;
  margin: 0 auto;
  *z-index: 100;
  *position: relative;

  .mo_view {
    display: none;
  }

  .logo {
    float: left;
    width: 104px;
    height: 24px;
    margin-top: 33px;
    @extend %bg;
    background-position: 0 0;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .btn_menu {
    display: none;
  }

  .select_wrap {
    display: none;
  }
}

//nav
.gnb {
  float: left;
  margin-left: 100px;

  .btn_close {
    display: none;
  }

  li {
    float: left;
    margin-right: 42px;

    a {
      @include font-helve;
      font-size: 15px;
      color: $font-normal;
      line-height: 90px;
      font-weight: bold;

      &:hover {
        color: $font-point;
      }

      &.link-career {
        position: relative;

        &::after {
          display: none;
          position: absolute;
          top: 50%;
          right: -22px;
          width: 18px;
          height: 18px;
          background: url(../img/pc/header_arrow.png) no-repeat center;
          background-size: contain;
          transform: translateY(-50%);
          content: '';
        }

        &:hover {
          &::after {
            display: block;
          }

        }
      }
    }

    &.on a {
      color: $font-point;
    }
  }
}

//select custom
.select_box {
  position: relative;
  display: inline-block;
  *display: inline;
  *zoom: 1;

  #header & {
    float: right;
    margin-top: 25px;
  }

  .selected {
    position: relative;
    display: inline-block;
    height: 40px;
    min-width: 69px;
    padding: 0 32px 0 29px;
    color: #999fa9;
    font-size: 13px;
    line-height: 42px;
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    @extend %bg;
    background-position: 100px -257px;
  }

  .select_list {
    display: none;
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    padding-bottom: 3px;
    z-index: 100;
    border: 1px solid #e6e6e6;
    border-top: 0;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-bottom-left-radius: 20px;
    -moz-border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #fff;

    ul {
      width: 100%;
      padding-top: 4px;

      li.on a {
        color: $font-point;
      }
    }

    a {
      display: block;
      padding: 0 29px;
      line-height: 34px;
      color: #999fa9;
      font-size: 13px;

      &:hover {
        color: $font-point;
      }
    }
  }

  &.open .select_list {
    display: block;
  }

  &.open .selected {
    border: 1px solid #e6e6e6;
    border-radius: 0;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    -moz-border-top-left-radius: 20px;
    -moz-border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-position: -115px -273px;
  }
}

@include breakpoint(mobile) {
  #header {
    height: auto;
    max-width: none;
    margin: auto;

    .mo_view {
      display: block;
    }

    .header_wrap {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 47px;
      background: #fff;
      z-index: 1000;
      padding-left: 20px;
      border-bottom: 1px solid #e6e6e6;
    }

    .logo {
      float: left;
      width: 61px;
      height: 15px;
      margin-top: 16px;
      @include sp;
      background-position: 0 0;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .gnb {
      float: none;
      margin-left: 0;

      li {
        float: none;
        margin: 0 0 4px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        display: block;
        padding: 0 20px;
        font-size: pxrem(28);
        line-height: 47px;
        font-family: $font-helveB;
        font-weight: bold;
        color: $font-normal;

        &.link-career {
          display: inline-block;

          &::after {
            display: block;
            right: -12px;
            width: 26px;
            height: 26px;
            margin-top: -2px;
            background: url(../img/mo/m_header_arrow.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      .btn_close {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 52px;
        height: 52px;
        padding: 17px;

        &:before {
          display: inline-block;
          width: 100%;
          height: 100%;
          @include sp;
          background-position: -24px -19px;
          content: '';
        }
      }
    }

    .nav_wrap {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      background: #fff;
      padding: 45px 0 35px;
      z-index: 100;

      #wrap.open & {
        display: block;
      }
    }

    .select_box {
      display: none;
    }

    .select_wrap {
      position: relative;
      display: inline-block;
      margin: 16px 0 0 20px;

      .selected {
        display: inline-block;
        height: 38px;
        min-width: 73px;
        padding: 0 9px;
        border: 1px solid #e6e6e6;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        font-size: pxrem(14);
        color: #666;
        line-height: 40px;
        text-align: center;
      }

      select {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
        z-index: 10;
      }
    }

    .btn_menu {
      display: block;
      float: right;
      width: 54px;
      height: 47px;
      padding: 16px 17px 15px;

      &:before {
        display: inline-block;
        width: 100%;
        height: 100%;
        @include sp;
        background-position: 0 -19px;
        content: '';
      }
    }

    &:after {
      @include clear;
    }
  }
}
