.notice {
  .container {
    border-top: 1px solid #e6e6e6;
  }

  .cont-wrap {
    max-width: 1130px;
    padding: 97px 0 80px;
    margin: 0 auto;
    box-sizing: content-box;
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    color: #353b42;

    &.is-hide {
      display: none;
    }
  }

  &__contents {
    margin-top: 40px;

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      text-align: center;
    }

    colgroup {
      &.date {
        width: 184px;
      }
    }

    th {
      height: 71px;
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #ced4db;
      border-width: 1px 0 1px 0;
      color: #353b42;
    }

    td {
      height: 55px;
      border-bottom: 1px solid #e9e9f1;
      font-size: 15px;
      color: #353b42;
    }
    &.is-hide {
      display: none;
    }
    .no-data {
      padding: 155px 0 154px;
      font-size: 26px;
      color: #cfcfcf;
      text-align: center;
    }
  }

  &__tit {
    a {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #353b42;
      text-align: left;
    }
  }

  &__date {
    width: 184px;
  }

  &__page {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 70px;
    font-size: 0;
    text-align: center;

    button {
      display: none;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      width: 24px;
      height: 24px;

      &::before {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        content: '';
      }

      &.btn-prev::before {
        @include sp-pc('btn_prev');
      }

      &.btn-next::before {
        @include sp-pc('btn_next');
      }

      &.is-active {
        display: block;
      }
    }

    &-inner {
      display: inline-flex;
    }

    a {
      display: inline-block;
      padding: 0 18px;
      font-size: 18px;
      color: #adb4bc;

      &.is-active {
        color: #353b42;
        cursor: default;
      }
    }
    &.is-hide {
      display: none;
    }
  }
}

.notice-detail {
  .cont-wrap {
    max-width: 1130px;
    padding: 97px 0 80px;
    margin: 0 auto;
    box-sizing: content-box;
  }

  &__title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 82px;
    border: 1px solid #e9e9f1;
    border-width: 1px 0 1px 0;
    font-size: 0;

    h3 {
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      color: #353b42;
    }

    span {
      display: inline-block;
      font-size: 14px;
      color: #595e65;
    }
  }

  &__contents {
    margin-top: 40px;
  }

  &__box {
    padding: 70px 0;
    border-bottom: 1px solid #e9e9f1;
    color: $color-black;
  }

  &__btn {
    margin-top: 40px;
    text-align: center;

    a {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 20px 31px;
      border: solid 1px #353b42;
      border-radius: 33px;
      font-size: 15px;
      color: #353b42;
    }
  }
}

@include breakpoint(mobile) {
  .notice {
    .content {
      padding-top: 40px;
    }

    .cont-wrap {
      margin: 0;
      padding: 0;
    }

    &__title {
      font-size: 22px;
    }

    &__contents {
      margin-top: 8px;

      thead {
        display: none;
      }

      tr {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        text-align: left;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }

      td {
        width: 100%;
        height: auto;
        margin-top: 26px;
        border: none;
        font-size: 14px;

        &.notice__tit {
          margin-top: 0;
        }

        a {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #353b42;
          text-align: left;
        }
      }
      .no-data {
        margin-top: 14px;
        padding: 110px 0 75px;
        font-size: 20px;
      }
    }

    &__page {
      button {
        &.btn-prev::before {
          @include sp-mo('btn_prev');
        }

        &.btn-next::before {
          @include sp-mo('btn_next');
        }
      }

      a {
        font-size: 16px;
      }
    }
  }

  .notice-detail {
    .content {
      padding-top: 40px;
    }

    .cont-wrap {
      margin: 0;
      padding: 0;
    }

    &__contents {
      margin-top: 12px;
    }

    &__title {
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: start;
      align-items: flex-start;
      border-top: none;
    }

    &__box {
      padding: 30px 0;
    }

    &__btn {
      a {
        height: 55px;
        padding: 0 24px;
        border-radius: 28px;
      }
    }
  }
}
