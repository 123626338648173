.privacy {
  .cont-left__date {
    &.is-hide {
      display: none;
    }
  }

  .cont-wrap {
    &__btn {
      overflow: hidden;

      .btn-prev {
        position: relative;
        float: right;
        margin: 20px 0;
        color: #4f5b6d;
        font-weight: bold;

        &:after {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #505b6c;
        }
      }

      &.is-hide {
        display: none;
      }
    }

    .no-data {
      padding: 155px 0 154px;
      font-size: 26px;
      color: #cfcfcf;
      text-align: center;
    }
  }
}

@include breakpoint(mobile) {
  .privacy {
    .cont-wrap {
      overflow: initial;

      .btn_prev_area {
        overflow: auto;
        color: #4f5b6d;
        font-size: 13px;

        .btn_prev_info {
          position: relative;
          display: inline-block;
          margin: 11px 0 10px;
          float: none;
          font-weight: normal;

          &:after {
            bottom: 0;
            background-color: #808080;
          }
        }
      }

      .no-data {
        margin-top: 14px;
        padding: 110px 0 75px;
        font-size: 20px;
      }
    }
  }
}
