:lang(en) {
  body,
  textarea,
  input,
  select,
  button {
    @include font-helve;
  }

  .main {
    &__section {
      &.news {
        border-top: 1px solid #e6e6e6;
      }
    }
  }

  .cont_wrap2 strong {
    font-family: inherit;
    font-weight: bold;
  }
}

:lang(ja) {
  body,
  textarea,
  input,
  select,
  button {
    @include font-jp;
  }

  .main {
    &__section {
      &.inc {
        .main {
          &__title {
            background: url(../img/pc/snow_jp.png) no-repeat 0 0;
            background-size: 247px 36px;
          }
  
          &__text {
            max-width: 720px;
          }
        }
      }
  
      &.news {
        border-top: 1px solid #e6e6e6;
      }
    }

    &__view {
      background: #fff url(/img/pc/bg_spot_v2.png) no-repeat 50% 0;
      background-size: 2048px 610px;
      -webkit-background-size: 2048px 610px;
    }

    &__text {
      max-width: 520px;
    }

    &__mo-img {
      display: none;
    }

    &__item {
      &-txt {
        font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', HiraKakuProN-W3, 'Hiragino Kaku Gothic Pro', NanumGothic, 'Malgun Gothic', sans-serif !important;
      }
    }
  }

  .cont_txt * {
    font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', HiraKakuProN-W3, 'Hiragino Kaku Gothic Pro', NanumGothic, 'Malgun Gothic', sans-serif !important;
  }

  .cont_wrap2 strong {
    font-family: inherit;
    font-weight: bold;
  }
  .contact {
    &__box {
      margin-top: 42px;

      p {
        margin-top: 16px;
      }
    }

    &__title {
      margin-top: 33px;
    }
  }
}

:lang(zh-Hans),
:lang(zh-Hant) {
  .main {
    &__section {
      &.news {
        border-top: 1px solid #e6e6e6;
      }
    }

    &__text {
      max-width: 780px;
    }
  }
}

:lang(zh-Hans) {

  body,
  textarea,
  input,
  select,
  button {
    @include font-zhans;
  }

  .cont_wrap2 strong {
    font-family: inherit;
    font-weight: bold;
  }

  .cont_txt * {
    font-family: 'Microsoft YaHei', '微软雅黑', Simsun, 'MS PGothic', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  }
}

:lang(zh-Hant) {

  body,
  textarea,
  input,
  select,
  button {
    @include font-zhant;
  }

  .cont_wrap2 strong {
    font-family: inherit;
    font-weight: bold;
  }

  .cont_txt * {
    font-family: 'Microsoft JhengHei', '微軟正黑體', 'MS PGothic', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  }
}

@include breakpoint(mobile) {
  :lang(ja) {
    .main {
      &__section {
        &.inc {
          .main__title {
            background: url(/img/mo/m_snow_jp.png) no-repeat 0 0;
            background-size: 179px 27px;
          }
        }
      }

      &__mo-img {
        display: block;
      }

      &__view {
        background: #fff url(/img/mo/m_bg_main_full_v2.png) no-repeat 50% 0;
        background-size: 1077px 321px;
        -webkit-background-size: 1077px 321px;
  
        @media (max-width: 1077px) {
          background-position: 0 0;
        }
  
        @media (max-width: 562px) {
          background: #fff url(/img/mo/m_bg_main_full_v3.png) no-repeat 0 0;
          background-size: 562px 321px;
          -webkit-background-size: 562px 321px;
        }
      }
    }

    .cont_txt * {
      font-family: -apple-system, system, HiraKakuProN-W3, "Apple SD Gothic Neo", "Helvetica Neue", Helvetica, "Droid Sans", sans-serif;
    }

    .contact {
      &__box {
        margin-top: 0;
  
        p {
          margin-top: 10px;
        }
      }
  
      &__title {
        margin-top: 0
      }
    }
  }

  :lang(en),
  :lang(ja),
  :lang(zh-Hans),
  :lang(zh-Hant) {
    body,
    textarea,
    input,
    select,
    button {
      font-family: -apple-system, system, HiraKakuProN-W3, "Apple SD Gothic Neo", "Helvetica Neue", Helvetica, "Droid Sans", sans-serif;
    }
  }
}
