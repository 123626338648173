.is-scroll-lock {
  overflow: hidden;
}

.layer-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-common-layer;
  overflow-y: auto;
  padding: 150px 0;
  background: rgba(0,0,0,.4);

  &__wrap {
    position: relative;
    width: 650px;
    margin: 0 auto;
    background: #fff;
  }
}

@include breakpoint(mobile) {
  .layer-popup {
    padding: 0;

    &__wrap {
      width: 100%;
      min-height: 100%;
    }
  }
}