.ppInfo {
  .container {
    min-height: calc(100% - 191px);
    border-top: 1px solid #e6e6e6;
  }

  .content {
    padding: 52px 0 80px;
  }

  .cont-left {
    float: left;
    width: 140px;
    margin-right: 62px;

    &__title {
      margin-top: 5px;
      font-size: 20px;
      line-height: 26px;
      color: #4f5b6d;
      font-weight: bold;

      .bar {
        display: block;
        width: 50px;
        height: 1px;
        margin: 17px 0 16px;
        background: #e6e6e6;
      }
    }

    p {
      margin-top: 10px;
      font-size: 13px;
      font-weight: bold;
      color: #666;
      line-height: 22px;
    }
  }

  .cont-wrap {
    overflow: hidden;

    &__title {
      font-size: 40px;
      color: #000;
      font-weight: normal;
    }
  }
}

@include breakpoint(mobile) {
  .ppInfo {
    .container {
      min-height: calc(100% - 136px);
      border-top: none;
    }

    .content {
      max-width: none;
      margin: 0;
      padding: 30px 20px 60px;
    }

    .cont-left {
      display: none;
    }

    .cont-wrap {
      overflow: initial;

      &__title {
        font-size: pxrem(22);
        line-height: 30px;
        color: #000;
        font-weight: normal;
      }
    }
  }
}
