body,
textarea,
input,
button {
  @include font-regular;
  color: $font-normal;
}

img {
  width: 100%;
  vertical-align: top;
}

.skip {
  height: 0;
}

.skip a {
  display: block;
  position: absolute;
  left: 0;
  top: -100px;
  width: 100%;
  height: 25px;
  text-align: center;
}

.skip a:focus,
#skip a:active {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  background: #000;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

#wrap {
  min-width: 1120px;
  height: 100%;
}

.br-mo {
  display: none;
}

// sub page
.main .container {
  position: relative;
  overflow: hidden;
}

.sub {
  .ico {
    @extend %sub_bg;
  }
}

.top_area {
  overflow: hidden;
  position: relative;
  height: 200px;
  background: url(/img/pc/bg_top.jpg) 100% 100%;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
  }

  .top_title {
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -560px;
    height: 43px;
    @extend %sub_bg;
  }
}

.content {
  max-width: 1120px;
  margin: 0 auto;
}

.cont_wrap {
  margin-top: 64px;

  .title_area {
    padding-bottom: 33px;
    border-bottom: 1px solid #999fa9;

    .title {
      font-size: 32px;
      color: #000;
      line-height: 46px;
      font-weight: normal;
    }

    .date {
      display: inline-block;
      margin-top: 15px;
      @include font-helve;
      font-size: 14px;
      color: #808080;
    }
  }

  .cont_area {
    padding: 40px 0 52px;
    border-bottom: 1px solid #999fa9;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #666;

    p {
      margin-top: 26px;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }
    }

    img + p {
      margin-top: 54px;
    }

    p + img {
      margin-top: 32px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .btn_area {
    margin-top: 40px;
    text-align: left;

    .button:first-child {
      *float: left;
    }
  }
}

// button
button {
  @include button_reset;
}

.button {
  @include button-common;

  .ico {
    display: inline-block;
    width: 8px;
    height: 15px;
    margin-top: 18px;
    vertical-align: top;
    *margin-top: 1px;
  }

  .prev {
    margin-right: 13px;
    background-position: -205px -54px;
  }

  .next {
    margin-left: 13px;
    background-position: -225px -54px;
  }

  & + .button {
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
  }
}

button {
  &.button {
    *width: auto;
    *overflow: visible;
    *min-width: 140px;
  }

  &.btn_s {
    *min-width: 122px;
  }

  &.btn_m {
    *min-width: 130px;
  }

  &.btn_l {
    *min-width: 150px;
  }

  &.btn_xl {
    *min-width: 250px;
  }
}

.blue {
  @include blue;
}

.gray {
  @include gray;
}

.btn_s {
  @include buttonS;
}

.btn_m {
  @include buttonM;
}

.btn_l {
  @include buttonL;
}

.btn_xl {
  @include buttonxL;
}

.btn_area {
  text-align: center;
}

.l_ja,
.l_zh {
  @include font-sim;
}

.l_en {
  @include font-helve;
}

.fr {
  float: right;
}

.sp {
  @extend %bg;
}

.sp_ico::before {
  content: '';
  @include sprite(pc);
}

.dim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.4;
  filter: alpha(opacity=40);
  z-index: 200;

  #wrap.open & {
    display: block;
  }
}

@include breakpoint(mobile) {
  body,
  textarea,
  input,
  button {
    @include font-regular-mo;
  }

  button,
  select {
    @include form_reset;
  }

  body,
  textarea,
  input,
  button,
  select {
    font-family: $font-regular;
    color: $font-gray;
  }

  ::-webkit-input-placeholder {
    color: #b2b2b2;
  }

  ::-moz-placeholder {
    color: #b2b2b2;
  }

  :-ms-input-placeholder {
    color: #b2b2b2;
  }

  .hc,
  .blind {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  .br-pc {
    display: none;
  }

  .br-mo {
    display: block;
  }

  .container {
    position: static;
    overflow: auto;
    min-height: calc(100% - 136px);
    padding-top: 47px;
    background: #fff;
  }

  .sp {
    @include sp;
  }

  .ico {
    @include sub_sp;
  }

  //button
  .button {
    @include button-common_mo;
  }

  .white {
    @include white;
  }

  .white_type2 {
    @include white_type2;
  }

  .white_trans {
    @include whitetranp;
  }

  .btn_s {
    @include buttonS_mo;
  }

  .btn_m {
    @include buttonM_mo;
  }

  .btn_l {
    @include buttonL_mo;
  }

  .btn_xl {
    @include buttonxL_mo;
  }

  .btn_w {
    @include buttonW_mo;
  }

  .btn_area {
    text-align: center;
  }

  #wrap {
    position: relative;
    min-width: auto;
    height: 100%;
  }

  .top_area {
    overflow: hidden;
    height: 100px;
    background: url(/img/mo/m_bg_top.jpg) 100% 100%;
    background-size: cover;

    .image {
      display: none;
    }

    .top_title {
      position: static;
      height: 27px;
      margin: 49px 0 0 20px;
      @include sub_sp;
    }
  }

  .content {
    padding: 25px 20px 50px;
  }

  .cont_wrap {
    .title_area {
      .title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: normal;
        color: #000;
        letter-spacing: -0.4px;
      }

      .date {
        display: block;
        margin-bottom: 20px;
        font-family: 'HelveticaNeue', Helvetica, -apple-system, Apple SD Gothic Neo, Arial, sans-serif;
        font-size: 12px;
        color: #808080;
        letter-spacing: -0.24px;
      }
    }

    .cont_area {
      padding: 25px 0;
      border-top: 1px solid #999fa9;
      border-bottom: 1px solid #999fa9;
      color: #666;
      line-height: 28px;
      letter-spacing: -0.28px;
    }
  }

  .dim {
    min-height: 100%;
    background: #505b6c;
    opacity: 0.7;
    z-index: 999;
  }
}
