@font-face {
  font-family: 'NanumBarunG';
  src: url('../font/NanumBarunGothic/NanumBarunGothic.eot');
  src: url('../font/NanumBarunGothic/NanumBarunGothic.eot?#iefix') format('embedded-opentype'),
    url('../font/NanumBarunGothic/NanumBarunGothic.woff') format('woff'),
    url('../font/NanumBarunGothic/NanumBarunGothic.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumBarunB';
  src: url('../font/NanumBarunGothicBold/NanumBarunGothicBold.eot');
  src: url('../font/NanumBarunGothicBold/NanumBarunGothicBold.eot?#iefix') format('embedded-opentype'),
    url('../font/NanumBarunGothicBold/NanumBarunGothicBold.woff') format('woff'),
    url('../font/NanumBarunGothicBold/NanumBarunGothicBold.ttf') format('truetype');
}

@mixin font-regular {
  font-family: 'NanumBarunG', '나눔바른고딕', 'NanumGothic', 돋움, 'Dotum', sans-serif;
}

@mixin font-bold {
  font-family: 'NanumBarunB', '나눔바른고딕', 'NanumGothic', 돋움, 'Dotum', sans-serif;
}

@mixin font-helve {
  font-family: 'HelveticaNeue', 'Helvetica', Arial, sans-serif;
}

@mixin font-helveM {
  font-family: 'HelveticaNeue-Medium', 'HelveticaNeue', Arial, sans-serif;
}

@mixin font-sim {
  font-family: SimSun, 'HelveticaNeue-Medium', 'HelveticaNeue', Arial, sans-serif;
}

@mixin font-jp {
  font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", HiraKakuProN-W3, "Hiragino Kaku Gothic Pro", NanumGothic, "Malgun Gothic", sans-serif;
}

@mixin font-zhant {
  font-family: "Microsoft JhengHei", "微軟正黑體", "MS PGothic", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

@mixin font-zhans {
  font-family: "Microsoft YaHei", "微软雅黑", Simsun, "MS PGothic", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

@mixin font-regular-mo {
  font-family: -apple-system,system,HiraKakuProN-W3,"Apple SD Gothic Neo","Helvetica Neue",Helvetica,"Droid Sans",sans-serif;
}
