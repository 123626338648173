#footer {
  border-top: 1px solid #e6e6e6;

  .footer_wrap {
    overflow: hidden;
    max-width: 1120px;
    padding: 25px 0 55px;
    margin: 0 auto;
    line-height: 21px;

    &-mo {
      display: none;
    }
  }

  address {
    float: left;
    margin-right: 26px;
    @include font-helve;
    color: $font-normal;
    font-size: 14px;
    font-weight: bold;
  }

  .business_info {
    overflow: hidden;
    font-size: 0;

    .lnk {
      display: inline-block;
      color: #808080;
      font-size: 13px;
      vertical-align: middle;

      &:before {
        display: inline-block;
        width: 1px;
        height: 13px;
        margin: 0 10px;
        background: #e6e6e6;
        vertical-align: middle;
        content: '';
      }

      &:first-child:before {
        display: none;
      }
    }
  
    .accent {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

@include breakpoint(mobile) {
  #footer {
    .footer_wrap {
      display: flex;
      padding: 24px 0;
      flex-direction: column-reverse;
      text-align: center;
    }

    address {
      float: none;
      margin: 4px auto 0;
      font-family: $font-helveM;
      font-weight: 400;
      font-size: pxrem(11);
    }

    .business_info {
      .lnk {
        font-size: pxrem(12);

        &:before {
          height: 11px;
          margin: -1px 10px 0 11px;
          background: #e5e5e5;
          content: '';
        }
      }
    }
  }
}
