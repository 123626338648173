.index {
  overflow: hidden;
  position: relative;

  .background {
    background: url(/img/pc/landscape_bg_new.jpg) no-repeat 50% 50%;
    background-size: cover;
    -webkit-background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .video-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @media (min-aspect-ratio : 16/9) {
    .video {
      height: 300%;
      top: -100%;
    }
  
    .video[data-useragent*='rv:11.0'] {
      width: 100%;
      height: auto;
      top: 0;
    }
  }
  
  @media (max-aspect-ratio : 16/9) {
    .video {
      width: 300%;
      left: -100%;
    }
  
    .video[data-useragent*='rv:11.0'] {
      width: auto;
      height: 100%;
      left: 0;
    }
  }
  
  @supports (object-fit: cover) {
    .video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .fog {
    background-color: #0cc8f0;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  
  .logo {
    position: absolute;
    top: 52%;
    width: 100%;
    height: 100%;
    margin-top: -330px;
    padding-top: 180px;
    background: url(/img/pc/bi_new_w_201809.png) no-repeat 50% 0;
    background-size: auto 440px;
    -webkit-background-size: auto 440px;
    z-index: 3;
  }
  
  .slogan {
    position: absolute;
    text-align: center;
    color: #fff;
    font-size: 24px;
    width: 100%;
    margin-top: 281px;
    font-family: "나눔고딕", "NanumGothic", "NanumGothicOTF", Apple Gothic, "맑은고딕", "Malgun Gothic", dotum, "Arial", verdana, "Trebuchet MS", "Lucida Grande", "HelveticaNeue", "Tahoma", "Helvetica", "hiragino kaku gothic pro", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
  
  }
  
  .download_wrap {
    position: absolute;
    top: 76%;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
  }
  
  .download {
    margin: 6px;
  }
  
  .download_img {
    width: auto;
    height: 60px;
    border: 0;
  
    &.mo {
      display: none;
    }
  }
  
  .site_wrap {
    position: absolute;
    top: 85%;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
  }
  
  .site {
    margin: 8px;
  }
  
  .site_img {
    width: auto;
    height: 34px;
    border: 0;
  
    &.mo {
      display: none;
    }
  }
  
  .snowcorp-copyright {
    font-family: "HelveticaNeue", "Helvetica", "Arial";
    position: absolute;
    top: 95.5%;
    z-index: 4;
    color: #fff;
    font-size: 14px;
    left: 1.2%;
  }
  
  .snowcorp-address {
    font-family: "Arial";
    position: absolute;
    top: 96.5%;
    z-index: 4;
    color: #fff;
    font-size: 12px;
    left: 1.2%;
    margin-top: 10px;
  }
  
  .snowcorp-contact {
    position: absolute;
    top: 1.2%;
    z-index: 4;
    color: #fff;
    font-size: 20px;
    right: 1.2%;
    text-decoration: none;
  }
  
  .snowcorp-contact a {
    font-family: "HelveticaNeue", "Helvetica", "Arial";
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 20px;
    font-weight: bold;
  }
  
  @include breakpoint(mobile) {
    .background {
      background: url(/img/mo/portranit_bg_new.jpg) no-repeat 50% 50%;
      background-size: cover;
      -webkit-background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  
    .fog {
      background-color: #0cc8f0;
      opacity: 0.9;
      z-index: 2;
    }
  
    .dimmed {
      background-color: #000000;
      opacity: 0.6;
      z-index: 4;
    }
  
    .fog, .dimmed {
      width: 100%;
      height: 100%;
      position: fixed;
    }
  
    .wechat_guide {
      position: absolute;
      top: 0;
      z-index: 5;
    }
  
    .logo {
      position: absolute;
      top: 20%;
      width: 100%;
      height: 100%;
      margin-top: 0px;
      padding-top: 0px;
      /*background: url(../img/bi_mobile.png) no-repeat 50% 0;*/
      background: url(/img/mo/bi_new_m_201809.png) no-repeat 50% 0;
      background-size: auto 43%;
      -webkit-background-size: auto 43%;
      z-index: 3;
    }
  
    .slogan {
      position: absolute;
      text-align: center;
      color: #fff;
      font-size: medium;
      width: 100%;
      top: 45%;
      margin-top: 0;
    }
  
    .download_wrap {
      position: absolute;
      top: 77%;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: 3;
    }
  
    .download {
      margin: 0.6%;
    }
  
    .download_img {
      height: 6.6%;
      margin-top: 2px;
  
      &.pc {
        display: none;
      }
  
      &.mo {
        display: inline-block;
      }
    }
  
    .site_wrap {
      position: absolute;
      top: 87%;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: 3;
    }
  
    .site {
      margin: 1%;
    }
  
    .site_img {
      height: 3.5%;
  
      &.pc {
        display: none;
      }
  
      &.mo {
        display: inline-block;
      }
    }
  
    .snowcorp-copyright {
      font-family: "HelveticaNeue", "Helvetica", "Arial";
      position: absolute;
      top: 95%;
      z-index: 4;
      color: #fff;
      font-size: 8pt;
      left: 41%;
    }
  
    .snowcorp-address {
      display: none;
    }
  
    .snowcorp-contact {
      position: absolute;
      top: 2%;
      z-index: 4;
      color: #fff;
      font-size: small;
      right: 4%;
      text-decoration: none;
    }
  
    .snowcorp-contact a {
      font-family: "HelveticaNeue", "Helvetica", "Arial";
      text-decoration: none;
      color: #FFFFFF;
      margin-left: 16px;
      font-weight: bold;
    }
  
    .video-bg {
      display: none;
    }
  }
  
}

