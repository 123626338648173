.contact {
  .content {
    padding-top: 80px;
    margin-bottom: 90px;

    &:after {
      display: block;
      clear: both;
      content: '';
    }
  }

  .top_area {
    .top_title {
      height: 55px;
      width: 281px;
      background-position: 0 -108px;
    }
  }

  &__left {
    float: left;
    width: 350px;
    margin-right: 59px;

    html[lang="ja"] & {
      width: 410px;
    }
  }


  &__map {
    overflow: hidden;

    #mapContainer {
      min-height: 490px;
    }

    .map {
      &__link {
        margin-top: 8px;
        text-align: right;

        a {
          font-size: 13px;
          color: #808080;
          margin-left: 10px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__title {
    font-size: 28px;
    margin-top: 40px;
    font-weight: normal;
  }

  &__info {
    overflow: hidden;
    margin-top: 15px;
    @include font-helve;
    font-size: 14px;
    line-height: 26px;
    color: #808080;

    dt {
      float: left;
      margin-right: 3px;
    }

    dd {
      overflow: hidden;
    }
  }

  &__btn-wrap {
    margin: 13px 0 0 -8px;

    .button {
      margin: 10px 0 0 8px;
      line-height: 48px;

      &.btn_link {
        vertical-align: top;
        line-height: 49px;
      }
    }
  }

  &__box {
    overflow: hidden;
    margin-top: 62px;
    border-top: 1px solid #ebebeb;

    &:first-child {
      margin-top: 0;
    }

    p {
      margin-top: 22px;
      font-weight: bold;
      font-size: 14px;
      color: #808080;
      line-height: 26px;
    }
  }
}

@include breakpoint(mobile) {
  .contact {
    .top_area .top_title {
      width: 141px;
      height: 27px;
      background-position: 0 -28px;
    }

    .content {
      display: flex;
      flex-direction: column-reverse;
      padding: 30px 20px;
      margin-bottom: 0;
      
    }

    &__map {
      width: 100%;

      #mapContainer {
        min-height: 300px;

        html[lang="ko"] & {
          min-height: 220px;
        }
      }

      a {
        display: block;

        img {
          height: 232px;
        }
      }

      .map {
        &__link {
          margin-top: 5px;
          text-align: right;
  
          a {
            display: inline-block;
            margin-left: 4px;
            font-size: pxrem(11);
            color: #808080;
          }
        }
      }
    }

    &__left {
      float: none;
      width: auto;
      margin-right: 0;

      html[lang="ja"] & {
        width: auto;
      }
    }

    &__title {
      margin-top: 0;
      font-size: pxrem(20);
      color: #000;
      font-weight: normal;
      line-height: 1.25;
    }

    &__info {
      overflow: initial;
      margin-top: 23px;
      font-family: $font-helve;
      color: #666;
      line-height: 20px;

      &:after {
        @include clear;
      }

      dt {
        float: left;
        margin-right: 5px;
      }

      dd {
        overflow: hidden;
      }
    }

    &__btn-wrap {
      margin: 11px 0 0;

      .button {
        margin: 8px 0 0;
        font-weight: normal;
      }

      .white {
        width: 100%;
        min-width: 274px;
        // margin: 19px 8px 0 0;
        line-height: 45px;
      }
    }

    &__box {
      margin-top: 0;
      padding: 29px 0 30px;
      border-top: 1px solid #f0f0f0;

      &:first-of-type {
        border-top: 0;
      }

      p {
        margin-top: 10px;
        font-weight: normal;
        color: #666;
        line-height: 23px;
      }
    }
  }
}
